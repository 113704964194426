import React, { useState } from 'react'
import Slider from 'react-slick';
import TeamCard from '../card/TeamCard';
import Modal from "react-bootstrap/Modal";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

function TeamSlider() {

  const [activeUser, setActiveUser] = useState('')
  const [activeIndex, setActiveIndex] = useState('')
  const teamdata = [
    {
      image: '/assets/team/fahad.png',
      title: 'Fahad',
      desc: 'Fahad Wahid is a highly accomplished computer science graduate who possesses a wealth of knowledge and expertise in the fields of project management and business development. With a strong educational background and practical experience, Fahad has consistently demonstrated their ability to excel in both technical and strategic aspects of their work. '
    },
    {
      image: '/assets/team/naki.png',
      title: 'Naqi',
      desc: `Muhammad Naqi is a computer science graduate with a strong interest in Web3 and blockchain technology. He has leveraged his technical expertise to become a valuable asset in the Business Development department and a key member of several Web3 startups. Muhammad's journey highlights his commitment to exploring the transformative potential of emerging technologies, bridging the gap between technology and business, and actively participating in the Web3 startup ecosystem. His vision, knowledge, and determination position him as a significant contributor to the future of Web3 and blockchain innovation.`
    },
    {
      image: '/assets/team/rob.png',
      title: 'Rob',
      desc: `Hi, my name is Rob Richardson. I'm an artist and writer that enjoys teaching others about, Graphic Novels, Storytelling, and Writing. I enjoy mentoring students in a one-on-one setting in the craft of Digital Painting and Graphic Novels.It's my goal to help as many creators as possible to create stories and art from their imaginations.I have worked professionally since 2007 in Comics, Toys, Graphic Design, and the 3D AssetMarkets. I have had the pleasure of working with great companies and brands such as Target,Costco, NFL, and National Geographic`
    },
    {
      image: '/assets/team/aadhiyathomas.png',
      title: 'Aadhiya',
      desc: 'Aadhiya Thomas-"Project Manager & AI Architect" ."With a strong foundation in AI architecture and a passion for project management, I thrive in merging the intricate with the organized. My expertise allows me to design intelligent systems that innovate and elevate while ensuring each project progresses precisely and efficiently.'
    },
    {
      image: '/assets/team/CamrinJon.png',
      title: 'Camrin',
      desc: 'CamrinJon VR game tester'
    },
    {
      image: '/assets/team/devin.png',
      title: 'Devin',
      desc: 'I am a highly motivated, creative software engineer with a diverse skill set. I have worked with artificial intelligence, machine learning and blockchain technologies.'
    },
    
    {
      image: '/assets/team/jonathan.png',
      title: 'Jonathan',
      desc: `Jonathan Capriola Author . Inventor . Dad . Mentor . Husband . Lover . AI architect . Coach . Blessed . Voting Board Member . Blockchain enthusiast . VR futurist`
    },
    {
      image: '/assets/team/gavin.png',
      title: 'Gavin',
      desc: `I'm Gavin Capriola, a 15 year old who finds exhilaration both on the ice and in the coding world. Every day presents a thrilling challenge, whether I'm practicing my hockey maneuvers or delving into intricate tech projects. My particular fascination lies in AI, ML, deep learning, and blockchain technology. If you're curious about what I've been up to, you can explore my GitHub profile at https://github.com/gavincaprio Over the years, I've accumulated knowledge from more than 60 courses on Udemy. These touch on a variety of subjects such as Python, AI, ML, deep learning, algorithms, TypeScript, Angular, Ionic, JavaScript, Solidity, Unreal Engine, and C++, among others. One of the highlights of my coding journey was when I clinched a $18,000 prize at 13, standing out among 90,000 other young coding enthusiasts in a competition. This achievement is a testament to the passion and hours I invest in refining my tech skills.`
    },
    {
      image: '/assets/team/kenbailey.png',
      title: 'Ken',
      desc: `Ken Bailey Design Specialist. 2-D/3-D Illustrator and Concept Artist. U of South Florida BA Art History`
    },
  ]
  const [success, setSuccess] = useState(false)
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 2000,
    nextArrow: <IoIosArrowForward className='n-arrow'/>,
    prevArrow: <IoIosArrowBack className='b-arrow'/>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  return (
    <>


      <div className="team-slider">
        <Slider {...settings}>
          {teamdata.map((res, index) => {
            return (
              <TeamCard success={success} setSuccess={setSuccess} res={res} index={index} setActiveIndex={setActiveIndex} />
            )

          })}
        </Slider>
      </div>

      <Modal
        show={success}
        onHide={() => setSuccess(false)}
        centered
        size="lg"
        className="team-modal-wrap"
      >
        <div className="modal-body">
          <div className="bg">
            <img src="/assets/images/success.png" alt="" className='img1' />
          </div>
          <div className="main-data">
            <div className="left">
              <img src={teamdata?.[activeIndex]?.image} alt="" className='img2' />
            </div>
            <div className="right">
              <h2>{teamdata?.[activeIndex]?.title}</h2>
              <p>{teamdata?.[activeIndex]?.desc}</p>
            </div>
          </div>


        </div>
      </Modal>
    </>
  )
}

export default TeamSlider