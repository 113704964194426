import React from 'react'

function WhitePaperBtn() {
    return (
       
         <svg width="180" height="47" viewBox="0 0 180 47" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect x="105" y="7.62939e-06" width="75" height="47" fill="url(#paint0_linear_327_77)" />
         <rect x="75" y="47" width="75" height="47" transform="rotate(-180 75 47)" fill="url(#paint1_linear_327_77)" />
         <rect x="0.75" y="0.750008" width="178.5" height="45.5" stroke="url(#paint2_linear_327_77)" strokeWidth="1.5" />
         <path d="M31.9893 23.958L34.1572 17.6006H37.541L31.9893 32L28.0342 21.7754L24.0938 32L18.542 17.6006H21.9258L24.0938 23.958L26.2617 17.6006H29.8213L31.9893 23.958ZM47.9561 23.1084V17.6006H51.3398V32H47.9561V26.4922H42.0527V32H38.6689V17.6006H42.0527V23.1084H47.9561ZM55.8516 32H52.4678V17.6006H55.8516V32ZM56.9795 20.9844V17.6006H69.6504V20.9844H65.1533V32H61.7549V20.9844H56.9795ZM70.7783 30.0664V17.6006H82.9072V20.9844H74.1621V23.1084H80.5635V26.4922H74.1768L74.1621 26.4336V28.6162H82.9072V32H72.7119C72.1748 32 71.7158 31.8145 71.335 31.4434C70.9639 31.0625 70.7783 30.6035 70.7783 30.0664ZM94.1426 17.6006C94.748 17.6006 95.2607 17.8154 95.6807 18.2451C96.1006 18.665 96.3105 19.1729 96.3105 19.7686V25.1592C96.3105 25.7549 96.1006 26.2676 95.6807 26.6973C95.2607 27.1172 94.748 27.3272 94.1426 27.3272H87.4336V32H84.0498V17.6006H94.1426ZM92.9268 23.9287V20.9844H87.4336V23.9287H92.9268ZM102.434 17.6006H106.213L112.204 32H96.4424L102.434 17.6006ZM101.54 28.6162H107.106L104.323 21.9219L101.54 28.6162ZM123.381 17.6006C123.986 17.6006 124.499 17.8154 124.919 18.2451C125.339 18.665 125.549 19.1729 125.549 19.7686V25.1592C125.549 25.7549 125.339 26.2676 124.919 26.6973C124.499 27.1172 123.986 27.3272 123.381 27.3272H116.672V32H113.288V17.6006H123.381ZM122.165 23.9287V20.9844H116.672V23.9287H122.165ZM126.677 30.0664V17.6006H138.806V20.9844H130.061V23.1084H136.462V26.4922H130.075L130.061 26.4336V28.6162H138.806V32H128.61C128.073 32 127.614 31.8145 127.233 31.4434C126.862 31.0625 126.677 30.6035 126.677 30.0664ZM152.209 25.1592C152.209 25.6865 152.038 26.1504 151.696 26.5508C151.354 26.9512 150.93 27.1953 150.422 27.2832L153.073 32H148.869L146.232 27.3272H143.332V32H139.948V17.6006H150.041C150.646 17.6006 151.159 17.8154 151.579 18.2451C151.999 18.665 152.209 19.1729 152.209 19.7686V25.1592ZM148.825 23.9287V20.9844H143.332V23.9287H148.825Z" fill="url(#paint3_linear_327_77)" />
         <defs>
             <linearGradient id="paint0_linear_327_77" x1="241.5" y1="23" x2="124.5" y2="23" gradientUnits="userSpaceOnUse">
                 <stop stopColor="#C26D30" />
                 <stop offset="1" stopColor="#C26D30" stopOpacity="0" />
             </linearGradient>
             <linearGradient id="paint1_linear_327_77" x1="211.5" y1="70" x2="94.5" y2="70" gradientUnits="userSpaceOnUse">
                 <stop stopColor="#C26D30" />
                 <stop offset="1" stopColor="#C26D30" stopOpacity="0" />
             </linearGradient>
             <linearGradient id="paint2_linear_327_77" x1="1.55329e-06" y1="23" x2="180" y2="23" gradientUnits="userSpaceOnUse">
                 <stop stopColor="#C26D30" />
                 <stop stopColor="#C26D30" />
                 <stop offset="0.416667" stopColor="#F3CC1A" stopOpacity="0" />
                 <stop offset="0.625" stopColor="#F3CC1A" stopOpacity="0" />
                 <stop offset="1" stopColor="#C26D30" />
             </linearGradient>
             <linearGradient id="paint3_linear_327_77" x1="18" y1="24" x2="38.5099" y2="-24.6692" gradientUnits="userSpaceOnUse">
                 <stop stopColor="#C26D30" />
                 <stop offset="1" stopColor="#F3CC1A" />
             </linearGradient>
         </defs>
     </svg>
    )
}

export default WhitePaperBtn