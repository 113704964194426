import React, { useContext,useState , useEffect, useRef } from 'react';
import './SaleStart.css'; // Import your CSS file
import Papa from 'papaparse';
import * as XLSX from 'xlsx'
import { Store } from '../../store/customStore';
import LoadingPage from '../../components/shared/LoadingPage';
import { AppContext } from '../../utils';

function SaleStart({loading}) {
const [fileData, setFileData] = useState([]);
const {account } = useContext(AppContext);
    
const { startSale, currentRound,GetCurrentRoundPrice,mainAccount,addWiteListForPrivateSale,error,
    setError,addBlackListForMarketTeam,removeWiteListForPrivateSale, setMessage, message, success, setSuccess,loader,setloader,addWiteListForMarketTeam,claimRewardByMarketTeamAddresses }=useContext(Store)

useEffect(()=>{
    GetCurrentRoundPrice()
},[currentRound,mainAccount,account])

const file = useRef(null)

const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
        return;
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
        try {
            const csvData = await parseCSV(file);
            checkDuplicateAddresses(csvData);
            setFileData(csvData);
        } catch (error) {
            setError(true); 
            setMessage('Error parsing CSV file: ' + error.message);
            // alert('Error parsing CSV file: ' + error.message);
        }
    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        try {
            const excelData = await parseExcel(file);
            // checkDuplicateAddresses(excelData);
            console.log(excelData,"excelData")
            setFileData(excelData);
        } catch (error) {
            setError(true); 
            setMessage('Error parsing Excel file: ' + error.message);
            // alert('Error parsing Excel file: ' + error.message);
        }
    } else {
        setError(true); 
        setMessage('Unsupported file format. Please upload a CSV or Excel file.');
        // alert('Unsupported file format. Please upload a CSV or Excel file.');
    }
};

const checkDuplicateAddresses = (data) => {
    const addressSet = new Set();
    console.log("addressSet",addressSet)
    for (const address of data) {
        if (addressSet.has(address)) {
            setError(true); 
            setMessage('Duplicate address found: ' + address);       
            // throw new Error('Duplicate address found: ' + address);
        }
        addressSet.add(address);
    }
};

const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: false, // Assuming the CSV doesn't have headers
            dynamicTyping: true,
            complete: (results) => {
                const csvData = results.data.map((row) => row[0]); // Assuming addresses are in the first column
                resolve(csvData);
            },
            error: (error) => {
                setError(true); 
                setMessage('CSV parsing error:', error); 
                reject(error);
            },
        });
    });
};

const parseExcel = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            const excelData = [];
            const addressSet = new Set(); // Create a set to check for duplicates

            workbook.SheetNames.forEach((sheetName) => {
                const sheet = workbook.Sheets[sheetName];
                const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                

                for (let i = 0; i < rows.length; i++) {
                const address = rows[i][0]; // Assuming addresses are in the first column   
                    if(address !== undefined){
                        if (addressSet.has(address)) {
                            reject(new Error('Duplicate address found: ' + address));
                            return;
                        }
                        addressSet.add(address);
                        excelData.push(address);
                    }
                }
            });

            resolve(excelData);
        };

        reader.onerror = (error) => {
            reject(new Error('Excel file reading error: ' + error.message));
            console.error('Excel file reading error:', error);
        };

        reader.readAsBinaryString(file);
    });
};



const addList = async()=> {
    addWiteListForPrivateSale(fileData);
    file.current.value = null;  
    setFileData([]) 
}

const removeList = async()=> {
    removeWiteListForPrivateSale(fileData);
    file.current.value = null;  
    setFileData([]) 
}




    const [teamAddress, setTeamAddress] = useState('');
    const [amountAddress, setAmount] = useState('');
    const [bteamAddress, setBTeamAddress] = useState('');

    const handleAddTeamAddress = async () => {
      // You can handle adding the market team address here
      if(teamAddress != undefined){
       await addWiteListForMarketTeam(teamAddress,amountAddress);
       setTeamAddress('')
       setAmount('')
      }
    };
    
    const handleAddBlackListTeamAddress = async () => {
        // You can handle adding the market team address here
        if(bteamAddress != undefined){
         await addBlackListForMarketTeam(bteamAddress);
         setBTeamAddress('')
        }
    };

return (
    <>
    {loading ?
        <LoadingPage/>
        :
        loader ?
        <LoadingPage/>
        :
        <div className='dashboard'>
        <div className='inpuField'>
       <h3>AddWhiteList</h3>
       <input ref={file} type="file" accept=".csv, .xlsx, .xls" onChange={handleFileChange} />
       <br/>
       <button onClick={()=>addList()} className="whitelist-button">Add Whitelist</button>
       <br/>
       <button onClick={()=>removeList()} className="whitelist-button_rev">Remove Whitelist</button>      
      </div>
      <button className={`start-button ${currentRound == 0 ? '' : 'disable'}`} disabled={currentRound == 0 ? false : true } onClick={()=>startSale()} >Start privateSale</button>
      <div className="button-group">
          <button className={`custom-button ${currentRound == 1 ? '' : 'disable'}`} disabled={currentRound == 1 ? false : true } onClick={()=>startSale()} >Start PublicSale 1</button>
          <button className={`custom-button ${currentRound == 2 ? '' : 'disable'}`}  disabled={currentRound == 2 ? false : true } onClick={()=>startSale()} >Start PublicSale 2</button>
          <button className={`custom-button ${currentRound == 3 ? '' : 'disable'}`} disabled={currentRound == 3 ? false : true }  onClick={()=>startSale()} >Start PublicSale 3</button>
      </div>
      {/* <button className="whitelist-button">Add Whitelist for MarketTeam</button> */}
      <div className="market-team-form">
      <input
        type="text"
        placeholder="Market Team Address"
        value={teamAddress}
        onChange={(e) => setTeamAddress(e.target.value)}
      />
       <input
        type="number"
        placeholder="Allow MarketTeam Address for claim "
        value={amountAddress}
        onChange={(e) => setAmount(e.target.value)}
      />
      <button className="add-address-button" onClick={handleAddTeamAddress}>
        Add Market Team Address
      </button>

      <button className="claim-reward-button_sub" onClick={()=> claimRewardByMarketTeamAddresses()}>
        Claim Reward
      </button>

      <input
        type="text"
        placeholder="BlackList Market Team Address"
        value={bteamAddress}
        onChange={(e) => setBTeamAddress(e.target.value)}
      />
      <button className="add-address-button" onClick={handleAddBlackListTeamAddress}>
        Add BlackList MarketTeam Address
      </button>

    </div>
    </div>

    }
    </>        
    );
}

export default SaleStart;
