import React, { useContext, useEffect, useState } from 'react'
import ConnectBtn from '../../components/Button/ConnectBtn'
import { Store } from '../../store/customStore'
import Modal from "react-bootstrap/Modal";
import { ethers, utils } from 'ethers';
import Aos from 'aos';
import 'aos/dist/aos.css'
import LoadingPage from '../../components/shared/LoadingPage';
import BuyBtn from '../../components/Button/BuyBtn';
import { useWeb3Modal } from '@web3modal/react';
import { AppContext } from '../../utils';
import { useAccount, useContractWrite } from 'wagmi';
import PEGTOKEN_CONTRACT_ADDRESS from '../../contractsData/PegCoin-address.json'
import PEGTOKEN_CONTRACT_ABI from '../../contractsData/PegCoin.json'

function PreSale({ loading }) {
    const { open } = useWeb3Modal();
    const { account } = useContext(AppContext);
    const {isConnected}= useAccount();

    // console.log(isConnected,"isConnected");

    const { connectWallet, checkIsWalletConnected, GetCurrentRoundPrice, BuyTokens, startSale, balanceOf } = useContext(Store) //functions

    const { roundPrice, currentRound, currentRoundSupply, remainingSupply, loader, mainAccount, userBalance, raisedAmount, show, setShow, walletConnected, error,
        setError, message, success, setSuccess, roundPriceForSale } = useContext(Store) //states

    const [count, setCount] = useState(0);
    const [Bar, setBar] = useState([]);



    useEffect(() => {
        checkIsWalletConnected();
        GetCurrentRoundPrice();
    }, [account, userBalance, mainAccount])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        Aos.init()
    }, [])




    const getNumberOfBars = async () => {

        let calPercentange = ((((+currentRoundSupply?.toString() - +remainingSupply?.toString())) * roundPriceForSale?.toString() / 10 ** 6) * 100) / ((+roundPriceForSale?.toString() / 10 ** 6) * (+currentRoundSupply));
        let percentage;

        if (Math.ceil(+calPercentange) > 5) {
            percentage = Math.ceil(Math.ceil(+calPercentange) / 6);
        }
        else {
            percentage = Math.ceil(+calPercentange)
        }

        let percentages = Math.ceil(+percentage);

        let bar = []
        if (((+roundPrice) * (+currentRoundSupply)) !== 0) {
            for (let index = 0; index < 16; index++) {
                if (index < percentages - 1) {
                    bar.push(1)
                }
                else if (index == percentages - 1) {
                    bar.push(2)
                }
                else {
                    bar.push(0)
                }
            }
        }
        setBar(bar);
    };


    useEffect(() => {
        getNumberOfBars();
    }, [raisedAmount, ((+roundPrice) * (+currentRoundSupply))])


    const handleInputChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value >= 0) {
            setCount(value);
        } else {
            setCount(0);
        }
    };

    const increment = () => {
        setCount(prevCount => prevCount + 1);
    };

    const decrement = () => {
        if (count > 0) {
            setCount(prevCount => prevCount - 1);
        }
    };

    return (
        <>
            {
                loader ?
                    <LoadingPage />
                    :
                    <>
                        <div className="presale">
                            <div className="presale-wrap">
                                <div className="sec-one">
                                    <div className="bg-overlay"></div>
                                    <video autoPlay loop muted>
                                        <source src="./assets/videos/presale.mp4" type="video/mp4" width={'100%'} />
                                    </video>
                                    <div className="content">
                                        <div className="left" data-aos="fade-right" data-aos-duration="2000">
                                            <h1>GET IN EARLY ON THE BOTPEGS PRESALE </h1>
                                            <p>The PEG token will power the whole BOTPEGS metaverse from development to the in-game currency. in-game store. Buy directly with USDT or ETH. After the presale ends, you will be able to claim your PEG tokens.</p>
                                        </div>
                                        <div className="right" data-aos="fade-left" data-aos-duration="2000">
                                            <div className="persale-card">
                                                <div className="cardwrap">
                                                    <div className="card-bg">
                                                        <img src="/assets/presale-images/card-bg.png" alt="" />
                                                    </div>
                                                    <div className="card-content">
                                                        <h2>{currentRound == 0 ? "PRIVATE SALE START SOON" : currentRound == 1 ? `PRVATE SALE Started` : `PUBLIC SALE Started`}</h2>
                                                        <h3>1 PEG = {roundPriceForSale?.toString() / 10 ** 6} $ </h3>
                                                        <p className="p1">{currentRound == 0 ? "SALE ROUND START SOON" : currentRound > 1 ? ` Round  ${(currentRound - 1)}  Started` : ""}</p>

                                                        <div className="loader-root">
                                                            <div className="loader-inner"
                                                                style={{ width: "max(min(25.5rem, 100% - 0.75rem), 3.375rem)", }}>
                                                                <div className="loader-bar-container">
                                                                    {Bar.map((x) => {
                                                                        return (
                                                                            <>
                                                                                {x == 1
                                                                                    &&
                                                                                    <div className="loader-bar" style={{ display: "block" }}></div>
                                                                                }
                                                                                {x == 2 &&
                                                                                    <div className="loader-bar last" style={{ display: "block" }}></div>
                                                                                }
                                                                                {x == 0 &&
                                                                                    <div className="loader-bar-black" style={{ display: "block" }}></div>
                                                                                }
                                                                                {/* BLINKING BLOCK */}
                                                                                {/* <div className="loader-bar last" style={{ display: "block" }}></div> */}
                                                                            </>
                                                                        )
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <p className="p2">Sold —  {(+currentRoundSupply?.toString() - +remainingSupply?.toString()).toFixed(4)} / {currentRoundSupply?.toString()} Tokens</p>

                                                        <p className="p3">Raised — {(((+currentRoundSupply?.toString() - +remainingSupply?.toString())) * roundPriceForSale?.toString() / 10 ** 6).toFixed(4)} $ /  {(+currentRoundSupply?.toString() * roundPriceForSale?.toString() / 10 ** 6)} $</p>

                                                        <p className="p4">You own — {Number(userBalance)?.toFixed(4)} PEG tokens</p>

                                                        <div className="button">
                                                            {isConnected ?
                                                                <>
                                                                    <div onClick={() => setShow(true)} className="button">
                                                                        <BuyBtn />
                                                                    </div>                                                                    </>

                                                                :
                                                                <span className='connect' onClick={() => open()}>
                                                                    <ConnectBtn />  </span>}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg">
                                        <img src="/assets/images/presale-bg.png" alt="" />
                                    </div>
                                </div >
                            </div >
                        </div >
                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            centered
                            size="lg"
                            className="buy-modal-wrap"
                        // backdrop="static"
                        // keyboard={false}
                        >
                            <div className="modal-body">
                                <img src="/assets/images/error-cross.png" onClick={() => setShow(false)} alt="" className='cancle' />
                                <div className="bg">
                                    <img src="/assets/images/buy.webp" alt="" />
                                </div>
                                <h2>Buy PEGTOKEN</h2>
                                <div className="quantity">
                                    <div className="left"><p>Enter Qty</p></div>
                                    <div className="right">
                                        <span className='decrement' onClick={decrement}>-</span>
                                        <input
                                            type="text"
                                            value={count}
                                            onChange={handleInputChange}
                                            min="0"
                                        />
                                        <span className='increament' onClick={increment}>+</span>
                                    </div>
                                </div>
                                <div className="amount">
                                    <p><span> {count} PEGTOKEN </span> = {Number(+count * +roundPriceForSale?.toString() / 10 ** 6).toFixed(4)} $</p>
                                </div>
                                <div
                                    onClick={() =>BuyTokens(count)}
                                    className="button">
                                  <BuyBtn />
                                </div>
                            </div>
                        </Modal>
                    </>
            }
        </>
    )
}

export default PreSale

