import React from 'react'
import LoadingBar from './LoadingBar'

function LoadingPage() {
  return (
    <div className='loading-page'>
      <div className="loading-page-wrap">
        <img src="/assets/images/loader-new.webp" alt="" />
        <LoadingBar />
        
      </div>
    </div>
  )
}

export default LoadingPage