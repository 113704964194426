import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Aos from 'aos';
import 'aos/dist/aos.css'

function Footer({ loading , state, setState, index, setIndex }) {
    useEffect(()=>{
        Aos.init()
      },[])
    return (
        <footer>
            <div className="footer-bg-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="footer-wrap">
                        <div className="left" data-aos="fade-right" data-aos-duration="2000">
                            <img src="/assets/logo/logo.webp" alt="" />
                        </div>
                        <div className="center" data-aos="fade-up" data-aos-duration="2000">
                            <div className='top hide-on-mobile'>
                                <ul>
                                    <li onClick={() =>setState('home')}><Link to={'/'}>HOME</Link></li>
                                    <li onClick={() =>setState('about')}><Link to={'/'}>ABOUT US</Link></li>
                                    <li onClick={() =>setState('team')}><Link to={'/'}>TEAM</Link></li>
                                    <li onClick={() =>setState('roadmap')}><Link to={'/'}>ROADMAP</Link></li>
                                    <li><Link to={'/presale'}>PRESALE</Link></li></ul>
                            </div>

                            <div className='top hide-on-desktop'>
                                <ul>
                                    <li onClick={() =>setState('home')}><Link to={'/'}>HOME</Link></li>
                                    <li onClick={() =>setState('about')}><Link to={'/'}>ABOUT US</Link></li>
                                    <li onClick={() =>setState('teams')}><Link to={'/'}>TEAM</Link></li>
                                    <li onClick={() =>setState('roadmap')}><Link to={'/'}>ROADMAP</Link></li>
                                    <li><Link to={'/presale'}>PRESALE</Link></li></ul>
                            </div>

                            <div className="bottom" >
                                <p>© 2023 BOTPEGS. All rights reserved.</p>
                            </div>
                        </div>
                        <div className="right" data-aos="fade-left" data-aos-duration="2000">
                            <img src="/assets/images/vr.png" alt="" />
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer