import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import Aos from 'aos';
import 'aos/dist/aos.css'

function TokenomicsList() {
    useEffect(() => {
        Aos.init()
    }, [])

    const [isVisible, setIsVisible] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true, // This will trigger the animation once when in view
    });

    useEffect(() => {
        setIsVisible(inView);
    }, [inView]);
    

    return (
        <>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Private Sale</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '10%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={10} duration={5} enableScrollSpy={true} />%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Public Sale R1</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '17.5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={17} duration={5} enableScrollSpy={true} />.5%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Public Sale R2</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '12.5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={12} duration={5} enableScrollSpy={true} />.5%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Public Sale R3</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '10%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={10} duration={5} enableScrollSpy={true} />%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Centralized Exchange Listing</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '2.5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={2} duration={5} enableScrollSpy={true} />.5%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Ecosystem</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '23.5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={23} duration={5} enableScrollSpy={true} />.5%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Owner</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '6.5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={6} duration={5} enableScrollSpy={true} />.5%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Development Team</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={5} duration={5} enableScrollSpy={true} />%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Marketing Team</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '10%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={10} duration={5} enableScrollSpy={true} />%</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p className="p1" data-aos="fade-right" data-aos-duration="2000">Play-2-Earn</p>
                </li>
                <li>
                    <div className="bar">
                        <div className="fill" data-aos="zoom-in-right" data-aos-duration="2000" style={{ width: '2.5%' }}></div>
                    </div>
                </li>
                <li>
                    <p className='p2' data-aos="fade-left" data-aos-duration="2000"><CountUp start={0} end={2} duration={5} enableScrollSpy={true} />.5%</p>
                </li>
            </ul>
        </>
    )
}

export default TokenomicsList