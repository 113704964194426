import './App.css';
import './AppResponsive.css';
import Header from './layout/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home/Home';
import PreSale from './pages/presale/PreSale';
import Footer from './layout/Footer/Footer';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import BottomNavBar from './layout/Header/BottomNavBar';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Suspense } from 'react';
import SaleStart from './pages/Dashboard/SaleStart';
import Modal from "react-bootstrap/Modal";
import { Store } from './store/customStore';
import LoadingPage from './components/shared/LoadingPage';
import { AppContext } from './utils';
import { useNetwork } from 'wagmi';
import 'react-modern-drawer/dist/index.css'
import PEGTOKEN_CONTRACT_ADDRESS from "../src/contractsData/PegCoin-address.json"
import PEGTOKEN_CONTRACT_ABI from "../src/contractsData/PegCoin.json"
import { useAccount, useWalletClient} from "wagmi";
import { useConnect, useBalance } from 'wagmi'
import { configureChains, mainnet } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import {
    useContractWrite,
    usePrepareContractWrite,
    useWaitForTransaction,
    useContractReads
  } from 'wagmi'

  import { readContract } from '@wagmi/core'
 

// background: radial-gradient(16.46% 58.20% at 8.23% 88.99%, #F3CC1A 0%, #F3CC1A 11%, #C9A917 31%, #7C680A 57%, #230F00 100%)

function App() {
  const { success, setSuccess, error, setError, message, loader, setloader } = useContext(Store)

  const [state, setState] = useState("")
  const [index, setIndex] = useState(0)
  const [activeSection, setActiveSection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0)
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  window.onload = () => {
    setLoading(false); // Website has fully loaded
  };
 
  useEffect(() => {
    if (account && chain && chain?.id) {
      setOpenNetworkSwitch(    
        chain?.id !== 5 ? (chain?.id !== 31337 ? true : false) : false
      );
    }
  }, [chain, account]);
 
  const changeScrollPosition = (event) => {
    setState(event)
    // console.log('working');
    if (state === 'homes') {
      document
        .getElementById(state)
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    if (state === 'roadmap') {
      document
        .getElementById(state)
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    if (state === 'teams') {
      document
        .getElementById(state)
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    if (state === 'team') {
      document
        .getElementById(state)
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    if (state === 'about') {
      document
        .getElementById(state)
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    if (state === 'tokenomics') {
      document
        .getElementById(state)
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
  }
  useEffect(() => {
  }, [state])


  
  return (
    <>
      <div style={{ pointerEvents: loader ? "none" : 'auto' }}>
        <BottomNavBar state={state}
          setState={setState}
          activeSection={activeSection}
          setActiveSection={setActiveSection} />
        <Header 
        tate={state}
        setState={changeScrollPosition}
        index={index}
        setIndex={setIndex}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        loading={loading}/>
        <Suspense fallback={<LoadingPage/>}>
          <Routes>
            <Route path="*" element={<Home
              state={state}
              setState={changeScrollPosition}
              index={index}
              setIndex={setIndex}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              loading={loading} />} />

            <Route path="/" element={<Home
              state={state}
              setState={changeScrollPosition}
              index={index}
              setIndex={setIndex}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              loading={loading} />} />

            <Route path="/presale" element={<PreSale
              state={state}
              setState={changeScrollPosition}
              index={index}
              setIndex={setIndex}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              loading={loading} />} />

            <Route path="/dashboard" element={<SaleStart
              state={state}
              setState={changeScrollPosition}
              index={index}
              setIndex={setIndex}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              loading={loading} />} />
                <Route path="/dashboard" element={<SaleStart 
                state={state}
                setState={changeScrollPosition}
                index={index}
                setIndex={setIndex}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                loading={loading}
                />} />
          </Routes>
        </Suspense>
        <Footer state={state}
          setState={setState}
          activeSection={activeSection}
          setActiveSection={setActiveSection} />


        {/* successModel */}
        <Modal
          show={success}
          onHide={() => setSuccess(false)}
          centered
          size="lg"
          className="sucess-modal-wrap"
        // backdrop="static"

        // keyboard={false}
        >
          <div className="modal-body">
            <img src="/assets/images/error-cross.png" onClick={() => setSuccess(false)} alt="" className='cancle' />
            <div className="bg">
              <img src="/assets/images/success.png" alt="" />
            </div>
            <img src="/assets/images/main-bot.png" alt="" className='img' />
            <h2>Successful</h2>
            <p></p>
          </div>
        </Modal>
        {/* errorModel */}
        <Modal
          show={error}
          onHide={() => setError(false)}
          centered
          size="lg"
          className="error-modal-wrap"
        // backdrop="static"
        // keyboard={false}
        >
          <div className="modal-body">

            <div className="bg">
              <img src="/assets/images/error.webp" alt="" />
            </div>
            <img src="/assets/images/error-cross.png" onClick={() => setError(false)} alt="" className='img' />
            <h2>{message}</h2>
          </div>
        </Modal>
      </div>


    </>

  );
}

export default App;
