import React, { useContext, useEffect, useState } from 'react'
import Drawer from 'react-modern-drawer'
import ConnectBtn from '../../components/Button/ConnectBtn'
import WhitePaperBtn from '../../components/Button/WhitePaperBtn'
import { Store } from '../../store/customStore';
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import { RxCross1 } from 'react-icons/rx'
import Aos from 'aos';
import 'aos/dist/aos.css'
import { useWeb3Modal } from '@web3modal/react';
import { AppContext } from '../../utils';
import { useAccount } from 'wagmi';

function Header({ loading, state, setState, index, setIndex }) {

    const { open } = useWeb3Modal();
    const { account } = useContext(AppContext);
    const { isConnected } = useAccount();

    const { connectWallet, mainAccount, walletConnected, checkIsWalletConnected } = useContext(Store);
    const [navDrawer, setNavDrawer] = useState(false)

    useEffect(() => {
        checkIsWalletConnected();
    }, [mainAccount, account])

    useEffect(() => {
        Aos.init()
    }, [])

    const openPDF = () => {
        const pdfUrl = '/assets/pdf/BotPeg-white-paper.pdf';
        window.open(pdfUrl, '_blank'); // Opens the PDF in a new tab or window
    };

    return (
        <>
            <header id='hide-on-mobile'>
                <div className="container-fluid">
                    <div className="header-wrap">
                        <div className="left" data-aos="fade-down" data-aos-duration="2000">
                            <Link to={'/'}>
                                <img src="/assets/logo/logo.webp" alt="logo" className='logo' />
                            </Link>
                        </div>
                        <div className="right" data-aos="fade-down" data-aos-duration="2000">
                            <span className='whitePaper' onClick={openPDF}>
                                <WhitePaperBtn />
                            </span>
                            {/* <span className='connect'> */}
                            <span className='connect' >
                                {isConnected ?
                                    <button className='wallet-connected'>
                                        <div className="left"></div>
                                        {account?.slice(0, 6)} ... {account?.slice(-6)}
                                        <div className="right"></div>
                                    </button>
                                    :
                                    <span className='connect' onClick={() => open()}>
                                        <ConnectBtn />  </span>}
                            </span>
                        </div>
                    </div>
                </div>
            </header>
            <header id='hide-on-desktop'>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-mobile">
                                <Link to={'/'}>
                                    <img src="/assets/logo/logo.webp" alt="logo" className='logo' />
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="right-mobile">
                                <GiHamburgerMenu onClick={() => { setNavDrawer(true) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <>
                <Drawer
                    open={navDrawer}
                    onClose={() => setNavDrawer(false)}
                    direction='right'
                    className='mobile-header-slider-bar'
                >
                    <div className="mobile-nav-drawer">
                        <div className="close-drawer" onClick={() => setNavDrawer(false)}>
                            <RxCross1 />
                        </div>
                        <div className="mobile-nav-listing">
                            <ul>
                                <li onClick={() => { setState('home'); setNavDrawer(false) }}> <Link to={'/'} >HOME</Link></li>
                                <li onClick={() => { setState('about'); setNavDrawer(false) }}> <Link to={'/'} >ABOUT US</Link></li>
                                <li onClick={() => { setState('teams'); setNavDrawer(false) }}> <Link to={'/'} >TEAM</Link></li>
                                <li onClick={() => { setState('roadmap'); setNavDrawer(false) }}> <Link to={'/'} >ROADMAP</Link></li>
                                <li onClick={() => { setNavDrawer(false) }}> <Link to={'/presale'} >PRESALE</Link></li>
                            </ul>
                        </div>
                        <div className='mobil-nav-button'>
                            <span className='connect' >
                                {walletConnected ?
                                    <button className='wallet-connected'>
                                        <div className="left"></div>
                                        {account?.slice(0, 6)} ... {account?.slice(-6)}
                                        <div className="right"></div>
                                    </button>
                                    :
                                    <button className='wallet-connected' onClick={() => { open(); setNavDrawer(false) }} >
                                        <div className="left"></div>
                                        Connect Wallet
                                        <div className="right"></div>
                                    </button>
                                    // <ConnectBtn />
                                }
                            </span>
                            <span className='whitePaper' onClick={openPDF}>
                                <button className='whitepaper-wrap'>
                                    <div className="left"></div>
                                    Whitepaper
                                    <div className="right"></div>
                                </button>
                            </span>
                        </div>
                        <div className="insta-tictoc">
                            <ul>
                                <li><a href='https://www.tiktok.com/@guitarfling?_t=8gjgUUU7TQ6&_r=1)' target='_blank'><img src='/assets/nav/tictoc.png' loading='lazy'/></a></li>
                                <li><a href='https://www.instagram.com/guitarfling/?igshid=MzRlODBiNWFlZA%3D%3D'  target='_blank'><img src='/assets/nav/instagram.png' loading='lazy'/></a></li>
                            </ul>
                        </div>

                    </div>
                </Drawer>
            </>
        </>
    )
}

export default Header