import React, { useEffect } from 'react'
import Slider from 'react-slick';
import Aos from 'aos';
import 'aos/dist/aos.css'

function RoadmapCard() {
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <Slider {...settings}>
      <div>
        <div style={{ margin: '20px 30px' }}>
          <div className="rode-map-card">
            <div className="left">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0219 0.524658L12.0219 6.79612L4.64438 14.1743L4.64438 29.8571L0.594967 33.9069L0.521197 12.0223L12.0219 0.524658Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="top">
              <svg width="167" height="34" viewBox="0 0 167 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60672 -7.23142e-06L11.2057 10.6L108.51 10.6L113.748 5.35738L153.107 5.35739L161.923 14.1743L161.923 29.8571L165.973 33.9069L166.042 12.0224L154.579 0.553364L154.579 0.237741L0.60672 -7.23142e-06Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="right-bottom">
              <svg width="6" height="43" viewBox="0 0 6 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8905 42.939L0.295921 37.3438L0.295921 35.9666L0.295922 7.11375L0.295923 5.73649L5.8905 0.141357" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom">
              <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.393946 33.3832L0.393946 27.1118L7.77142 19.7336L7.77142 4.05492L11.8208 0.00512695L11.8905 21.8856L0.393946 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom-center">
              <svg width="118" height="11" viewBox="0 0 118 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.9758 5.38324L0.908653 5.38324L6.13026 10.6095L30.0046 10.6095L35.5992 5.01025L36.9722 5.01025L81.7042 5.01025L83.0813 5.01025L88.6759 10.6095L112.55 10.6095L117.772 5.38325L90.7047 5.38325L85.4421 0.161131L81.7042 0.161131L36.9722 0.161129L33.2384 0.161129L27.9758 5.38324Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="left-bottom">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0218 33.3832L12.0218 27.1118L4.64438 19.7336L4.64438 4.05492L0.594969 0.00512645L0.521196 21.8856L12.0218 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="content" data-aos="zoom-in" data-aos-duration="2000">
              <h3>Q1 2024</h3>
              <ul>
                <li>January 2023:</li>
                <li>Private Sale</li>
                <li>Feburary 2023:</li>
                <li>Public Sale Round 1</li>
                <li>March 2023:</li>
                <li>Public Sale Round 2</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ margin: '20px 30px' }}>
          <div className="rode-map-card">
            <div className="left">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0219 0.524658L12.0219 6.79612L4.64438 14.1743L4.64438 29.8571L0.594967 33.9069L0.521197 12.0223L12.0219 0.524658Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="top">
              <svg width="167" height="34" viewBox="0 0 167 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60672 -7.23142e-06L11.2057 10.6L108.51 10.6L113.748 5.35738L153.107 5.35739L161.923 14.1743L161.923 29.8571L165.973 33.9069L166.042 12.0224L154.579 0.553364L154.579 0.237741L0.60672 -7.23142e-06Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="right-bottom">
              <svg width="6" height="43" viewBox="0 0 6 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8905 42.939L0.295921 37.3438L0.295921 35.9666L0.295922 7.11375L0.295923 5.73649L5.8905 0.141357" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom">
              <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.393946 33.3832L0.393946 27.1118L7.77142 19.7336L7.77142 4.05492L11.8208 0.00512695L11.8905 21.8856L0.393946 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom-center">
              <svg width="118" height="11" viewBox="0 0 118 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.9758 5.38324L0.908653 5.38324L6.13026 10.6095L30.0046 10.6095L35.5992 5.01025L36.9722 5.01025L81.7042 5.01025L83.0813 5.01025L88.6759 10.6095L112.55 10.6095L117.772 5.38325L90.7047 5.38325L85.4421 0.161131L81.7042 0.161131L36.9722 0.161129L33.2384 0.161129L27.9758 5.38324Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="left-bottom">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0218 33.3832L12.0218 27.1118L4.64438 19.7336L4.64438 4.05492L0.594969 0.00512645L0.521196 21.8856L12.0218 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="content" data-aos="zoom-in" data-aos-duration="2000">
              <h3>Q2 2024</h3>
              <ul>
                <li>April 2024</li>
                <li>Public Sale Round 3</li>
                <li>NFT Minting: </li>
                <li>June 2024</li>
                <li>NFT Reveal</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ margin: '20px 30px' }}>
          <div className="rode-map-card">
            <div className="left">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0219 0.524658L12.0219 6.79612L4.64438 14.1743L4.64438 29.8571L0.594967 33.9069L0.521197 12.0223L12.0219 0.524658Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="top">
              <svg width="167" height="34" viewBox="0 0 167 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60672 -7.23142e-06L11.2057 10.6L108.51 10.6L113.748 5.35738L153.107 5.35739L161.923 14.1743L161.923 29.8571L165.973 33.9069L166.042 12.0224L154.579 0.553364L154.579 0.237741L0.60672 -7.23142e-06Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="right-bottom">
              <svg width="6" height="43" viewBox="0 0 6 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8905 42.939L0.295921 37.3438L0.295921 35.9666L0.295922 7.11375L0.295923 5.73649L5.8905 0.141357" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom">
              <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.393946 33.3832L0.393946 27.1118L7.77142 19.7336L7.77142 4.05492L11.8208 0.00512695L11.8905 21.8856L0.393946 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom-center">
              <svg width="118" height="11" viewBox="0 0 118 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.9758 5.38324L0.908653 5.38324L6.13026 10.6095L30.0046 10.6095L35.5992 5.01025L36.9722 5.01025L81.7042 5.01025L83.0813 5.01025L88.6759 10.6095L112.55 10.6095L117.772 5.38325L90.7047 5.38325L85.4421 0.161131L81.7042 0.161131L36.9722 0.161129L33.2384 0.161129L27.9758 5.38324Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="left-bottom">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0218 33.3832L12.0218 27.1118L4.64438 19.7336L4.64438 4.05492L0.594969 0.00512645L0.521196 21.8856L12.0218 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="content" data-aos="zoom-in" data-aos-duration="2000">
              <h3>Q3 2024</h3>
              <ul>
                <li>Launch Version 1 of the Game</li>
                <li>Staking Platform</li>
                <li>Special 4th of July iGuitarNFTs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ margin: '20px 30px' }}>
          <div className="rode-map-card">
            <div className="left">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0219 0.524658L12.0219 6.79612L4.64438 14.1743L4.64438 29.8571L0.594967 33.9069L0.521197 12.0223L12.0219 0.524658Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="top">
              <svg width="167" height="34" viewBox="0 0 167 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60672 -7.23142e-06L11.2057 10.6L108.51 10.6L113.748 5.35738L153.107 5.35739L161.923 14.1743L161.923 29.8571L165.973 33.9069L166.042 12.0224L154.579 0.553364L154.579 0.237741L0.60672 -7.23142e-06Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="right-bottom">
              <svg width="6" height="43" viewBox="0 0 6 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8905 42.939L0.295921 37.3438L0.295921 35.9666L0.295922 7.11375L0.295923 5.73649L5.8905 0.141357" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom">
              <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.393946 33.3832L0.393946 27.1118L7.77142 19.7336L7.77142 4.05492L11.8208 0.00512695L11.8905 21.8856L0.393946 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom-center">
              <svg width="118" height="11" viewBox="0 0 118 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.9758 5.38324L0.908653 5.38324L6.13026 10.6095L30.0046 10.6095L35.5992 5.01025L36.9722 5.01025L81.7042 5.01025L83.0813 5.01025L88.6759 10.6095L112.55 10.6095L117.772 5.38325L90.7047 5.38325L85.4421 0.161131L81.7042 0.161131L36.9722 0.161129L33.2384 0.161129L27.9758 5.38324Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="left-bottom">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0218 33.3832L12.0218 27.1118L4.64438 19.7336L4.64438 4.05492L0.594969 0.00512645L0.521196 21.8856L12.0218 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="content" data-aos="zoom-in" data-aos-duration="2000">
              <h3>Q4 2024</h3>
              <ul>
                <li>Listing on Decentralized Exchanges</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ margin: '20px 30px' }}>
          <div className="rode-map-card">
            <div className="left">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0219 0.524658L12.0219 6.79612L4.64438 14.1743L4.64438 29.8571L0.594967 33.9069L0.521197 12.0223L12.0219 0.524658Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="top">
              <svg width="167" height="34" viewBox="0 0 167 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60672 -7.23142e-06L11.2057 10.6L108.51 10.6L113.748 5.35738L153.107 5.35739L161.923 14.1743L161.923 29.8571L165.973 33.9069L166.042 12.0224L154.579 0.553364L154.579 0.237741L0.60672 -7.23142e-06Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="right-bottom">
              <svg width="6" height="43" viewBox="0 0 6 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8905 42.939L0.295921 37.3438L0.295921 35.9666L0.295922 7.11375L0.295923 5.73649L5.8905 0.141357" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom">
              <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.393946 33.3832L0.393946 27.1118L7.77142 19.7336L7.77142 4.05492L11.8208 0.00512695L11.8905 21.8856L0.393946 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="bottom-center">
              <svg width="118" height="11" viewBox="0 0 118 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.9758 5.38324L0.908653 5.38324L6.13026 10.6095L30.0046 10.6095L35.5992 5.01025L36.9722 5.01025L81.7042 5.01025L83.0813 5.01025L88.6759 10.6095L112.55 10.6095L117.772 5.38325L90.7047 5.38325L85.4421 0.161131L81.7042 0.161131L36.9722 0.161129L33.2384 0.161129L27.9758 5.38324Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="left-bottom">
              <svg width="13" height="34" viewBox="0 0 13 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0218 33.3832L12.0218 27.1118L4.64438 19.7336L4.64438 4.05492L0.594969 0.00512645L0.521196 21.8856L12.0218 33.3832Z" fill="#A4A4A4" />
              </svg>
            </div>
            <div className="content" data-aos="zoom-in" data-aos-duration="2000">
              <h3>Q1 2025</h3>
              <ul>
                <li>Listing on Centralized Exchanges</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Slider>

  )
}

export default RoadmapCard