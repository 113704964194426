import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import PreSale from '../presale/PreSale'
import PreSaleBtn from '../../components/Button/PreSaleBtn'
import TokenomicsList from '../../components/shared/TokenomicsList'
import RoadmapCard from '../../components/card/RoadmapCard'
import TeamSlider from '../../components/Slider/TeamSlider'
import Aos from 'aos';
import 'aos/dist/aos.css'
import LoadingPage from '../../components/shared/LoadingPage'

function Home({ loading, state, setState }) {
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(true);

    const [activeSection, setActiveSection] = useState("");


    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevCount) => prevCount + 1);
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])

    useEffect(() => {
        Aos.init()
    }, [])
    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 5000);
    }, [])

    useEffect(() => {

        window.scrollTo({ top: 0 });
        document
            .getElementById(state)
            ?.scrollIntoView?.({ block: "start", behavior: "smooth" });

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const sectionId = entry.target.getAttribute("id");
                if (sectionId && entry.isIntersecting) {
                    setActiveSection(sectionId);
                    // setState(sectionId)
                    console.log(activeSection);
                }
            });
        });
        document.querySelectorAll("section").forEach((section) => {
            observer.observe(section);
        });

        return () => observer.disconnect();
    }, [state]);


    return (
        <>
        {loader ? <LoadingPage/> :
            <div className="home">
                <div className="home-wrap">
                    <section className="sec-one" id='homes'>
                        <div className="bg-overlay"></div>
                        <video autoPlay loop muted>
                            <source src="./assets/videos/sec-1.mp4" type="video/mp4" width={'100%'} />
                        </video>
                        <div className="main-content">
                            <div className="container-fluid">
                                <div className="main-content-wrap">

                                    <div className="left" data-aos="zoom-in" data-aos-duration="2000">
                                        <h1 >Explore the Frontier of Play-to-Earn: <span>Guitar Fling</span></h1>
                                        <span >- Where VR, AI, and Blockchain Unite!</span>
                                    </div>
                                    <div className="right">
                                        <img src="/assets/images/main-bot.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sec-two" id='about'>
                        <h2 data-aos="zoom-in" data-aos-duration="2000">ABOUT GAME</h2>
                        <p data-aos="fade-up" data-aos-duration="2000">BOTPEGS is the next generation Gaming Metaverse with integrated innovative GameFi experiences. BOTPEGS users are able to play AAA GameFi games and earn $PEG, buy and trade game assets as NFTs, as well as attend virtual events such as concerts & NFT exhibitions.</p>

                        <div className="button" data-aos="fade-up" data-aos-duration="2000">
                            <Link to={"/presale"}  >  <PreSaleBtn /></Link>
                        </div>
                    </section>
                    <div className="sec-t-f" style={{ position: 'relative', top: '0' }}>
                        <div className='sec-t-ff' style={{
                            background: `url("assets/images/sec-threebg.png")  cover`,
                            background: `url("assets/images/sec-threebg.png") -${count}px 0px / cover`,

                        }}>
                        </div>

                        <section className="sec-three" id='tokenomics'>
                            <div className="container">
                                <div className="row">
                                    <h2 data-aos="zoom-in" data-aos-duration="2000">Tokenomics</h2>
                                    <div className="content">
                                        <div className="bg">
                                            <img src="/assets/images/s-3-content-bg.png" alt="" />
                                        </div>
                                        <div className="left">
                                            <img src="/assets/images/sec-three.png" alt="" />
                                        </div>
                                        <div className="right">
                                            <TokenomicsList />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="sec-four" id='roadmap'>
                            <div className="container">
                                <div className="row">
                                    <h2 data-aos="zoom-in" data-aos-duration="2000">ROADMAP</h2>
                                    <div className="card-wrap">
                                        <RoadmapCard />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="sec-five hide-on-mobile" id='team'>
                            <h2 data-aos="zoom-in" data-aos-duration="2000">OUR TEAM</h2>
                            <div className="container" data-aos="fade-up" data-aos-duration="2000">
                                <div className="row">
                                    <TeamSlider />
                                </div>
                            </div>
                    </section>
                    <section className="sec-five hide-on-desktop" id='teams'>
                            <h2 data-aos="zoom-in" data-aos-duration="2000">OUR TEAM</h2>
                            <div className="container">
                                <div className="row">
                                    <TeamSlider />
                                </div>
                            </div>
                    </section>
                </div>
            </div>
        }


        </>


    )
}

export default Home