import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function BottomNavBar({ loading , state, setState, index, setIndex }) {

    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let scrollTimeout;

        const handleScroll = () => {
            setIsScrolling(true);

            // Clear the previous scrollTimeout every time the user scrolls
            clearTimeout(scrollTimeout);

            // Set a timeout to reset isScrolling after a certain delay (e.g., 200 milliseconds)
            scrollTimeout = setTimeout(() => {
                setIsScrolling(false);
            }, 200); // Adjust the delay as needed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimeout); // Clear the timeout on component unmount
        };
    }, []);

    const [showNav, setShowNav] = useState(false)
    return (
        <div className="new-nav" onMouseLeave={() => { setShowNav(false) }}>
            <div className={`before ${!showNav ? 'show' : 'hide'}`} style={{opacity : isScrolling ? '0.2' : '1'}}>
                <ul>
                    <li><a href='https://www.tiktok.com/@guitarfling?_t=8gjgUUU7TQ6&_r=1)' target='_blank'><img src='/assets/nav/tictoc.png' /></a></li>
                    <li onMouseOver={() => { setShowNav(true) }}><Link><img src='/assets/nav/getar.png' /></Link></li>
                    <li><a href='https://www.instagram.com/guitarfling/?igshid=MzRlODBiNWFlZA%3D%3D'  target='_blank'><img src='/assets/nav/instagram.png' /></a></li>
                </ul>
            </div>
            <div className={`after ${showNav ? 'show' : 'hide'}`}>
                <ul>
                    <li onClick={() =>setState('homes')}><Link to={'/'}>Home</Link></li>
                    <li onClick={() =>setState('about')}><Link to={'/'}>Abouts us</Link></li>
                    <li onClick={() =>setState('tokenomics')}><Link to={'/'}>Tokenomics</Link></li>
                    <li ><Link to={'/presale'}>PRESALE</Link></li>
                    <li onClick={() =>setState('roadmap')}><Link to={'/'}>Roadmap</Link></li>
                    <li onClick={() =>setState('team')}><Link to={'/'}>TEAM</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default BottomNavBar