import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { goerli, mainnet} from "wagmi/chains";
import { ConectivityProvider } from "./utils";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from './store/customStore';


const localhost = ({
  id: 31337,
  name: 'Localhost',
  network: 'localhost',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['http://localhost:8545'] },
    public: { http: ['http://localhost:8545'] },
  },
})

const chains = [goerli, localhost];

const projectId = "9d0b155ab39b98bda73713b3b49458d7";
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient,
});



const ethereumClient = new EthereumClient(wagmiConfig,chains);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <StoreProvider>
    <BrowserRouter>
    <ConectivityProvider>
      <App />
      </ConectivityProvider>
    </BrowserRouter>
    </StoreProvider>
    <Web3Modal
    themeMode="dark"
    projectId={projectId}
    ethereumClient={ethereumClient}
  />
    </WagmiConfig>
  
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
