import React from 'react'
import TeamCardBg from '../shared/TeamCardBg'

function TeamCard({success , setSuccess, res , index , setActiveIndex}) {
    return (
        <div className="team-card">
            <div className="left"></div>
            <div className="right"></div>
            <div className="top"></div>
            <div className="bottom"></div>
            <div className="content">
                <img src={res?.image} alt="" />
                <h3>{res?.title}</h3>
                <p>{res?.desc}</p>
                <h4 onClick={() =>{setSuccess(true) ; setActiveIndex(index)}}>Read More</h4>
            </div>
        </div>
    )
}

export default TeamCard