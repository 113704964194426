import { ethers, providers, utils } from "ethers";
import React, { useState, useEffect, createContext } from "react";
import PEGTOKEN_CONTRACT_ADDRESS from "../contractsData/PegCoin-address.json"
import PEGTOKEN_CONTRACT_ABI from "../contractsData/PegCoin.json"
import { useAccount, useWalletClient, useNetwork } from "wagmi";
import { useConnect, useBalance } from 'wagmi'
import { configureChains, mainnet } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import {
    useContractWrite,
    usePrepareContractWrite,
    useWaitForTransaction,
    useContractReads
} from 'wagmi'
import { readContract } from '@wagmi/core'

export const Store = createContext();


const getProviderPEGTOKENContrat = () => {
    //  const provider = new ethers.providers.Web3Provider(ethereum);  // TODO

    // const provider = new ethers.providers.JsonRpcProvider(process.env.LocalRpc);
    // let RPC = process.env.REACT_APP_RPC;
    let RPC = process.env.REACT_APP_SEPO_RPC;
    // console.log("RPC",RPC);

    // const customRpcProvider = new providers.JsonRpcProvider(RPC);
    // console.log("RPC",customRpcProvider);

    const provider = new ethers.providers.JsonRpcProvider(RPC);//"http://localhost:8545/"

    // const provider = new ethers.providers.JsonRpcProvider(RPC);

    // const provider = new ethers.providers.JsonRpcProvider(
    //   `https://eth-goerli.g.alchemy.com/v2/${process.env.TESTNET_API}`
    // );

    // const provider = new ethers.providers.JsonRpcProvider(
    //   `https://eth-mainnet.g.alchemy.com/v2/${process.env.MAINNET_API}`
    // );
    const PEGContract = new ethers.Contract(PEGTOKEN_CONTRACT_ADDRESS.address, PEGTOKEN_CONTRACT_ABI.abi, provider);
    return PEGContract;
}






export const StoreProvider = ({ children }) => {
    const [mainAccount, setAccount] = useState("")
    const [walletConnected, setWalletConnected] = useState(false);
    const [loader, setloader] = useState(false)
    const [roundPrice, setRoundPrice] = useState("")
    const [currentRound, setCurrentRound] = useState(0)
    const [currentRoundSupply, setCurrentRoundSupply] = useState("")
    const [remainingSupply, setRemainingSupply] = useState("")
    const [userBalance, setUserBalance] = useState("")
    const [roundPriceForSale, setRoundPriceForSale] = useState("")
    const [raisedAmount, setRaisedAmount] = useState("")
    const [show, setShow] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const [raisedAmountInEth, setRaisedAmountInEth] = useState(0)
    const { ethereum } = window;



    // const { data, isLoading } = useContractReads({
    //     contracts: [
    //       {
    //         // Get the total number of NFTs available
    //         address: PEGTOKEN_CONTRACT_ADDRESS.address,
    //         abi: PEGTOKEN_CONTRACT_ABI.abi,
    //         functionName: 'round',
    //         args: [],
    //       },
    //       {
    //         // Get the name of the NFT collection
    //         address: PEGTOKEN_CONTRACT_ADDRESS.address,
    //         abi: PEGTOKEN_CONTRACT_ABI.abi,
    //         functionName: 'remainingSupply',
    //         args: [],
    //       },
    //       {
    //         // Get the number of NFTs that an address owns
    //         address: PEGTOKEN_CONTRACT_ADDRESS.address,
    //         abi: PEGTOKEN_CONTRACT_ABI.abi,
    //         functionName: 'raisedAmount',
    //         args: [],
    //     },]
    //   })



    const getPEGTokenContrat = () => {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const PEGContract = new ethers.Contract(PEGTOKEN_CONTRACT_ADDRESS.address, PEGTOKEN_CONTRACT_ABI.abi, signer);
        return PEGContract;
    }



    const connectWallet = async () => {
        try {
            // setloader(true);
            if (!ethereum) return setError(true), setMessage("Please install Metamask");
            // toast.info("Please install Metamask");;
            await ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        // chainId: "0x5" //Goerli
                        // chainId: "0x89", //PolygonMainnet
                        chainId: "0xaa36a7", //sepolia
                        // chainId: "0x1", //Miannet
                        // chainId: "0x7A69" //localHost TODO
                        // chainId:"0x13881" //mumbai
                        // chainId:"0x61"//bnb

                    },
                ],
            });
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccount(accounts[0]);
            // await balanceOf(accounts[0]);
            // Get provider from Metamask
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            // setloader(false);
        } catch (err) {
            // setloader(false);
            console.log(err.message);
        }
    };

    const checkIsWalletConnected = async () => {
        try {

            window.ethereum.on("accountsChanged", async function (accounts) {
                setAccount(accounts[0]);
                // await balanceOf(accounts[0]);
            });
            window.ethereum.on('chainChanged', async (chainId) => {
                console.log("chainId", chainId);
                if (chainId != "0xaa36a7") { //TODO
                    await ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [
                            {
                                // chainId: "0x5" //Goerli
                                // chainId: "0x89", //PolygonMainnet
                                chainId: "0xaa36a7", //sepolia
                                // chainId: "0x1", //Miannet
                                // chainId: "0x7A69" //localHost TODO
                                // chainId:"0x13881" //mumbai
                                // chainId:"0x61"//bnb

                            },
                        ],
                    });
                }
            })

            const accounts = await ethereum.request({ method: "eth_accounts" });
            if (accounts.length) {
                setAccount(accounts[0]);
                setWalletConnected(true);
                balanceOf(accounts[0]);
            } else {
                console.log("No account Found");
                setWalletConnected(false);
            }
        } catch (err) {
            setWalletConnected(false);
            throw new Error("No ethereum Object");
        }
    };

    const GetCurrentRoundPrice = async () => {
        try {
            setloader(true)

            // const data = await readContract({
            //     address: PEGTOKEN_CONTRACT_ADDRESS.address,
            //     abi: PEGTOKEN_CONTRACT_ABI.abi,
            //     functionName: 'round',
            //     mainAccount,
            // })

            // const data2 = await readContract({
            //     address: PEGTOKEN_CONTRACT_ADDRESS.address,
            //     abi: PEGTOKEN_CONTRACT_ABI.abi,
            //     functionName: 'remainingSupply',
            //     mainAccount,
            // })

            // const data3 = await readContract({
            //     address: PEGTOKEN_CONTRACT_ADDRESS.address,
            //     abi: PEGTOKEN_CONTRACT_ABI.abi,
            //     functionName: 'raisedAmount',
            //     mainAccount,
            // })

            const getRound = await getProviderPEGTOKENContrat().round();
            setCurrentRound(getRound?.toString());
            // console.log("data",data?.toString())
            const tokens = await getProviderPEGTOKENContrat().remainingSupply();
            const RST = ethers.utils.formatEther(tokens?.toString());
            setRemainingSupply(RST);

            const getRaisedAmount = await getProviderPEGTOKENContrat().raisedAmount();
            const RA = ethers.utils.formatEther(getRaisedAmount?.toString());
            setRaisedAmount(RA?.toString());

            if (getRound?.toString() == 0) {
                setRoundPrice(0);
                setCurrentRoundSupply(0);
                setRoundPriceForSale(0)
            }
            else if (getRound?.toString() == 1) {
                // uint256 public privateSale   = 0.1 * 10**6;
                // uint256 public constant privateRoundLimit = 20_00_000 ether;
                let tokenInDoller = await getProviderPEGTOKENContrat().getLatestUSDTPrice();
                const roundPrice = (0.1 * (10 ** 6));
                const TotalSupply = 2000000;
                const RP = (+tokens?.toString() / +tokenInDoller);

                setRoundPrice(RP);
                setRoundPriceForSale(roundPrice)
                setCurrentRoundSupply(TotalSupply);

            }
            else if (getRound?.toString() == 2) {
                // uint256 public round1Price   = 0.3   * 10**6;
                // uint256 public constant round1Limit = 35_00_000 ether;
                let tokenInDoller = await getProviderPEGTOKENContrat().getLatestUSDTPrice();
                const roundPrice = (0.3 * (10 ** 6));
                const TotalSupply = 3500000;

                const RP = (+tokens?.toString() / +tokenInDoller);

                setRoundPriceForSale(roundPrice)
                setRoundPrice(RP);
                setCurrentRoundSupply(TotalSupply);
            }
            else if (getRound?.toString() == 3) {
                // uint256 public round2Price   = 0.36  * 10**6;
                // uint256 public constant round2Limit = 25_00_000 ether;
                let tokenInDoller = await getProviderPEGTOKENContrat().getLatestUSDTPrice();
                const roundPrice = (0.36 * (10 ** 6));
                const TotalSupply = 2500000;
                const RP = (+tokens?.toString() / +tokenInDoller);
                setRoundPriceForSale(roundPrice)
                setRoundPrice(RP);
                setCurrentRoundSupply(TotalSupply);
            }
            else if (getRound?.toString() == 4) {
                // uint256 public round3Price   = 0.375 * 10**6;
                // uint256 public constant round3Limit = 20_00_000 ether;
                let tokenInDoller = await getProviderPEGTOKENContrat().getLatestUSDTPrice();
                const roundPrice = (0.375 * (10 ** 6));
                const TotalSupply = 2000000;
                const RP = (+tokens?.toString() / +tokenInDoller);
                setRoundPriceForSale(roundPrice)
                setRoundPrice(ethers.utils.formatEther(RP));
                setCurrentRoundSupply(TotalSupply);
            }
            else {
                console.log("someThingWrong");
            }
            setloader(false);
        } catch (error) {
            setloader(false);
            // setError(true);
            // setMessage(JSON.stringify(error.reason));
            console.log(error);
        }
    }

    const BuyTokens = async (amount) => {
       
        setloader(true);
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {

            const tokens = ethers.utils.parseEther(amount.toString());
            let remain = Math.round(remainingSupply);

            if (+amount?.toString() < 10) return setloader(false), setShow(false), setError(true), setMessage("Please buy minimum 10 tokens"); //toast.error("Tokens Must be < Remaining Supply");
            if (currentRound == 0) return setloader(false), setShow(false), setError(true), setMessage("wait for private sale"); //toast.error("Tokens Must be < Remaining Supply");
            if (remain?.toString() == 0) return setloader(false), setShow(false), setError(true), setMessage("Round Supply exceed"); //toast.error("Tokens Must be < Remaining Supply");
            if (+amount?.toString() > remain?.toString()) return setloader(false), setShow(false), setError(true), setMessage("Tokens Must be less then Remaining Supply"); //toast.error("Tokens Must be < Remaining Supply");

            let Price = await getPEGTokenContrat().sellTokenInETHPrice(tokens, roundPriceForSale);


            // const Price = ((((Number(roundPriceForSale.toString())) * (Number(tokens.toString()))) / (10 ** 18)));
            // console.log("Price", Price);
            // var numberValue = parseFloat(Price);
            // var stringTotalNumber = numberValue.toLocaleString();
            // var stringTotalNumber2 = stringTotalNumber.toString().replace(/,/g, "");
            // console.log("stringTotalNumber2", stringTotalNumber2);

            if (Price <= 0) return setloader(false), setError(true), setMessage("Price Must be > 0"); // toast.error("Price Must be > 0");

            // return Price.toString();

            // const buyEth = await readContract({
            //     address: PEGTOKEN_CONTRACT_ADDRESS.address,
            //     abi: PEGTOKEN_CONTRACT_ABI.abi,
            //     functionName: 'mint',
            //     // args: [tokens?.toString()], // mint 1 NFT
            //     // // send ETH according to the price of the NFT:
            //     // overrides: { value: utils.parseEther("10"), },
            // })


            const respon = await getPEGTokenContrat().mint(tokens?.toString(), { value: Price });
            if (respon) {
                await respon.wait();
                setloader(false)
                setShow(false)
                setSuccess(true)
                setMessage("successfuly Minted"); // toast.success("successfuly Minted");
                await GetCurrentRoundPrice();
                await balanceOf(mainAccount);
            }
        } catch (error) {
            // toast.error(`${JSON.stringify(error.reason)}`);
            setloader(false)
            setShow(false);
            setError(true);
            setMessage(JSON.stringify(error.reason));
            console.log(error);
            return false;
        }

    }

    const startSale = async () => {
        setloader(true)
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {
            if (currentRound.toString() == 4) return setloader(false), setError(true), setMessage("All the rounds have finished"); //toast.error("All the rounds have finished");
            const response = await getPEGTokenContrat().startTheSale();
            await response.wait();
            setloader(false);
            setSuccess(true)
            setMessage("successfuly"); // toast.success("successfuly");
            await GetCurrentRoundPrice();
            await balanceOf(mainAccount);
        } catch (error) {
            setloader(false);
            setError(true);
            setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }
    }

    const balanceOf = async (mainAccount) => {
        try {
            // console.log("mainAccount",mainAccount)
            if(mainAccount){
                const respon = await getPEGTokenContrat().balanceOf(mainAccount);
                console.log("respon",respon)
                const blc = ethers.utils.formatEther(respon?.toString());
                setUserBalance(blc);
            }
        } catch (error) {
            // setError(true);
            // setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }

    }

    const addWiteListForPrivateSale = async (items) => {
        setloader(true);
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {
            if (items.length > 0) {
                const response = await getPEGTokenContrat().addWhitelistForPrivateSale(items);
                await response.wait();
                setloader(false)
                setSuccess(true)
                setMessage("successfuly"); // toast.success("successfuly");
                await GetCurrentRoundPrice();
                await balanceOf(mainAccount);
            } else {
                setloader(false)
                setError(true);
                setMessage("Please Add addreses into File");
            }

        } catch (error) {
            setloader(false)
            setError(true);
            setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }

    }

    const removeWiteListForPrivateSale = async (items) => {
        setloader(true);
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {
            if (items.length > 0) {
                const response = await getPEGTokenContrat().removeMultipleFromWhitelist(items);
                await response.wait();
                setloader(false)
                setSuccess(true)
                setMessage("successfuly"); // toast.success("successfuly");
                await GetCurrentRoundPrice();
                await balanceOf(mainAccount);
            } else {
                setloader(false)
                setError(true);
                setMessage("Please Add addreses into File");
            }

        } catch (error) {
            setloader(false)
            setError(true);
            setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }

    }


    const addWiteListForMarketTeam = async (addr, amount) => {
        setloader(true);
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {
            if (addr !== undefined ) {
                const response = await getPEGTokenContrat().allowClaimToMarketTeam(addr, amount);
                await response.wait();
                setloader(false)
                setSuccess(true)
                setMessage("successfuly"); // toast.success("successfuly");
                await GetCurrentRoundPrice();
                await balanceOf(mainAccount);
            } else {
                setloader(false)
                setError(true);
                setMessage("Please Add addreses into File");
            }

        } catch (error) {
            setloader(false)
            setError(true);
            setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }
    }

    const addBlackListForMarketTeam = async (addr) => {
        setloader(true);
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {
            if (addr !== undefined ) {
                const response = await getPEGTokenContrat().blacklistMartketTeamAddr(addr);
                await response.wait();
                setloader(false)
                setSuccess(true)
                setMessage("successfuly"); // toast.success("successfuly");
                await GetCurrentRoundPrice();
                await balanceOf(mainAccount);
            } else {
                setloader(false)
                setError(true);
                setMessage("Please Add addreses into File");
            }

        } catch (error) {
            setloader(false)
            setError(true);
            setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }
    }

    const claimRewardByMarketTeamAddresses = async () => {
        setloader(true);
        if (!walletConnected) {
            connectWallet();
            return setloader(false);
        }
        try {
            const response = await getPEGTokenContrat().claimRewardByMarketTeamAddresses();
            await response.wait();
            setloader(false)
            setSuccess(true)
            setMessage("successfuly"); // toast.success("successfuly");
            await GetCurrentRoundPrice();
            await balanceOf(mainAccount);

        } catch (error) {
            setloader(false)
            setError(true);
            setMessage(JSON.stringify(error.reason)); //toast.error(`${JSON.stringify(error.reason)}`);
            console.log(error);
        }
    }



    return (
        <>
            <Store.Provider
                value={{
                    removeWiteListForPrivateSale,
                    addBlackListForMarketTeam,
                    claimRewardByMarketTeamAddresses,
                    addWiteListForMarketTeam,
                    addWiteListForPrivateSale,
                    error,
                    setError,
                    connectWallet,
                    raisedAmount,
                    balanceOf,
                    startSale,
                    connectWallet,
                    checkIsWalletConnected,
                    GetCurrentRoundPrice,
                    BuyTokens,
                    mainAccount,
                    walletConnected,
                    loader,
                    setloader,
                    roundPrice,
                    currentRound,
                    currentRoundSupply,
                    remainingSupply,
                    userBalance,
                    show,
                    setShow,
                    message,
                    success,
                    setSuccess,
                    setMessage,
                    roundPriceForSale
                }}
            >
                {children}
            </Store.Provider>


        </>
    );
}