import React from 'react'

function BuyBtn() {
    return (
        <svg width="118" height="43" viewBox="0 0 118 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="80.5859" width="37.4146" height="43" fill="url(#paint0_linear_743_955)" />
            <rect x="37.4141" y="43" width="37.4146" height="43" transform="rotate(-180 37.4141 43)" fill="url(#paint1_linear_743_955)" />
            <rect x="0.75" y="0.75" width="116.5" height="41.5" stroke="url(#paint2_linear_743_955)" stroke-width="1.5" />
            <path d="M40.8594 26.1406H42.3008C43.2227 26.1406 43.9219 25.9414 44.3984 25.543C44.875 25.1367 45.1133 24.5469 45.1133 23.7734C45.1133 23.0469 44.875 22.4922 44.3984 22.1094C43.9297 21.7266 43.2461 21.5352 42.3477 21.5352H40.8594V26.1406ZM40.8594 18.6992H41.6797C42.7891 18.6992 43.5742 18.543 44.0352 18.2305C44.4961 17.918 44.7266 17.3945 44.7266 16.6602C44.7266 15.9414 44.5039 15.4297 44.0586 15.125C43.6211 14.8203 42.8516 14.668 41.75 14.668H40.8594V18.6992ZM37.1914 29V11.8438H40.4023C42.0664 11.8438 43.2812 11.9102 44.0469 12.043C44.8203 12.168 45.4883 12.3789 46.0508 12.6758C46.7148 13.0586 47.2266 13.5625 47.5859 14.1875C47.9531 14.8125 48.1367 15.5195 48.1367 16.3086C48.1367 17.3242 47.8359 18.1406 47.2344 18.7578C46.6328 19.3672 45.7344 19.7734 44.5391 19.9766C45.9219 20.1797 46.9648 20.6289 47.668 21.3242C48.3789 22.0195 48.7344 22.9453 48.7344 24.1016C48.7344 24.9453 48.5312 25.7188 48.125 26.4219C47.7188 27.1172 47.1602 27.6562 46.4492 28.0391C45.8164 28.3828 45.0742 28.6289 44.2227 28.7773C43.3711 28.9258 41.9609 29 39.9922 29H39.6172H37.1914ZM66.2891 11.8438V22.5195C66.2891 24.8477 65.6758 26.5859 64.4492 27.7344C63.2227 28.8828 61.375 29.457 58.9062 29.457C56.3984 29.457 54.5312 28.8828 53.3047 27.7344C52.0781 26.5859 51.4648 24.8477 51.4648 22.5195V11.8438H55.1094V21.9453C55.1094 23.3594 55.4219 24.4258 56.0469 25.1445C56.6719 25.8555 57.6055 26.2109 58.8477 26.2109C60.0977 26.2109 61.043 25.8516 61.6836 25.1328C62.3242 24.4141 62.6445 23.3516 62.6445 21.9453V11.8438H66.2891ZM73.625 29V21.1719L67.8125 11.8438H72.1133L74.6562 16.543C74.7812 16.7773 74.9102 17.0742 75.043 17.4336C75.1758 17.793 75.3125 18.207 75.4531 18.6758C75.5625 18.2773 75.6836 17.9023 75.8164 17.5508C75.957 17.1992 76.1133 16.8555 76.2852 16.5195L78.8047 11.8438H83.082L77.293 21.1719V29H73.625Z" fill="url(#paint3_linear_743_955)" />
            <defs>
                <linearGradient id="paint0_linear_743_955" x1="148.681" y1="21.0426" x2="90.3137" y2="21.0426" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#046793" />
                    <stop offset="1" stop-color="#046793" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_743_955" x1="105.509" y1="64.0426" x2="47.1419" y2="64.0426" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#046793" />
                    <stop offset="1" stop-color="#046793" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_743_955" x1="1.01827e-06" y1="21.0425" x2="118" y2="21.0425" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00CBE2" />
                    <stop stop-color="#00CBE2" />
                    <stop offset="0.416667" stop-color="#07D9F1" stop-opacity="0" />
                    <stop offset="0.625" stop-color="#07D9F1" stop-opacity="0" />
                    <stop offset="1" stop-color="#00CBE2" />
                </linearGradient>
                <linearGradient id="paint3_linear_743_955" x1="35" y1="19.963" x2="64.8504" y2="-3.31305" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#009EE3" />
                    <stop offset="1" stop-color="#00CBE2" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default BuyBtn